<template>
    <div v-if="isLoading" class="loader bg-[#fff] rounded-[8px] p-2">
        <LoadingComponent />
    </div>

    <div v-else class="company-container bg-[#fff] rounded-[8px] p-2">
        <div class="navigation-tabs">
            <div class="nav-links">
                <router-link to="/companyprofile" :class="`${this.$route.path == '/companyprofile' ? 'active' : ''}`">
                    <font-awesome-icon :icon="['fas', 'list-check']" class="nav-icon" />
                    <span>Company profile</span>
                </router-link>

                <router-link to="/settings" :class="`${this.$route.path == '/userrofile' ? 'active' : ''}`">
                    <font-awesome-icon :icon="['fas', 'chart-gantt']" class="nav-icon" />
                    <span>Recruiter profile</span>
                </router-link>

                <router-link to="/InvitedCoworkerTab" :class="`${this.$route.path == '/InvitedCoworkerTab' ? 'active' : ''}`">
                    <font-awesome-icon :icon="['fas', 'chart-gantt']" class="nav-icon" />
                    <span>Invited Coworkers</span>
                </router-link>
            </div>
        </div>

        <div class="p-4 bg-gray-100 rounded-lg">
            <!-- Search Bar -->
            <div class="mb-4">
                <input type="text" placeholder="Search" class="w-full px-4 py-2 text-gray-500 border border-gray-300 rounded-md focus:outline-none focus:border-blue-400" v-model="searchQuery" />
            </div>

            <!-- Table -->
            <div class="overflow-x-auto">
                <table class="min-w-full bg-white border rounded-lg">
                    <thead>
                        <tr class="text-left border-b">
                            <th class="p-4 text-gray-600">First Name</th>
                            <th class="p-4 text-gray-600">Last Name</th>
                            <th class="p-4 text-gray-600">Email</th>

                            <th class="p-4 text-gray-600">Date Invited</th>
                            <th class="p-4 text-gray-600">Role</th>
                            <th class="p-4 text-gray-600">Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="project in filteredProjects" :key="project._id" class="border-b hover:bg-gray-50">
                            <td class="p-4">{{ project.first_name }}</td>
                            <td class="p-4">{{ project.last_name }}</td>
                            <td class="p-4">{{ project.email }}</td>
                            <td class="p-4">
                                {{ project.register ? new Date(project.register).toISOString().split("T")[0] : "N/A" }}
                            </td>
                            <td v-if="project.role != 'owner'" class="p-4">
                                {{ project.role || "Colaborator" }}
                            </td>
                            <td v-else class="p-4">
                                {{ "Admin" }}
                            </td>
                            <td class="p-4 text-center text-gray-500 cursor-pointer">
                                <!-- Edit Button -->
                                <button
                                    v-if="project.role != 'admin' && project.role != 'Admin' && project.role != 'Owner' && project.role != 'owner'"
                                    title="Edit"
                                    @click="(showModal1 = true), (currentID = project._id)"
                                    class="text-blue-500 hover:text-blue-700 mx-1"
                                >
                                    <i class="fas fa-edit"></i>
                                    <!-- Font Awesome edit icon -->
                                </button>

                                <!-- Modal -->
                                <div v-if="showModal1" class="fixed inset-0 flex items-center justify-center bg-black bg-opacity-10 z-50">
                                    <div class="bg-white w-96 p-6 rounded-lg shadow-lg relative">
                                        <h2 class="text-start ml-3 font-semibold text-gray-800 align-left mb-4">Edit candidate company role</h2>
                                        <div class="text-base w-full font-medium px-3 mb-5">
                                            <InputList v-model="selectedRole" firstItem="Role" :items="['Admin', 'Colaborator']" />
                                        </div>

                                        <!-- Buttons -->
                                        <div class="flex justify-end">
                                            <button @click="cancelDelete" class="bg-[#F7F9FC] hover:border hover:border-[#2196f3] text-black px-4 py-2 m-3 rounded-md">Cancel</button>
                                            <button
                                                type="submit"
                                                @click="confirmEdit"
                                                class="bg-[#2196f3] text-white px-4 py-2 m-3 rounded-md hover:bg-white hover:text-black hover:border hover:border-[#2196f3]"
                                            >
                                                Confirm
                                            </button>
                                        </div>
                                    </div>
                                </div>

                                <!-- Delete Button -->
                                <button
                                    v-if="project.role != 'admin' && project.role != 'Admin' && project.role != 'Owner' && project.role != 'owner'"
                                    title="Delete"
                                    @click="(showModal = true), (currentID = project._id)"
                                    class="text-red-500 hover:text-red-700 mx-1"
                                >
                                    <i class="fas fa-trash"></i>
                                    <!-- Font Awesome delete icon -->
                                </button>

                                <!-- Modal -->
                                <div v-if="showModal" class="fixed inset-0 flex items-center justify-center bg-black bg-opacity-10 z-50">
                                    <div class="bg-white w-96 p-6 rounded-lg shadow-lg relative">
                                        <h2 class="text-lg font-semibold text-gray-800 mb-4">Confirm Delete</h2>
                                        <p class="text-gray-600 mb-6">Are you sure you want to delete this teamate?</p>

                                        <!-- Buttons -->
                                        <div class="flex flex-row justify-between items-center">
                                            <button @click="cancelDelete" class="bg-[#F7F9FC] hover:border hover:border-[#2196f3] text-black px-4 py-2 m-3 rounded-md">Cancel</button>
                                            <button
                                                type="submit"
                                                @click="confirmDelete"
                                                class="bg-[#FF0000] text-white px-4 py-2 m-3 rounded-md hover:bg-white hover:text-black hover:border hover:border-[#FF0000]"
                                            >
                                                Confirm
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</template>

<script>
import axios from "axios";
import { useStore } from "@/store/index";

import InputList from "@/components/ReusableComponents/ListInput.vue";

export default {
    components: { InputList },

    setup() {
        const Store = useStore();
        return { Store };
    },
    data() {
        return {
            showDeletePopup: false,
            showModal: false,
            showModal1: false,
            currentID: "",
            searchQuery: "",
            selectedRole: "",
            activeTab: "all",
            projects1: [],
            showPopup: false,
            selectedOption: "",
            options: ["Admin", "Colaborator"],
        };
    },
    async mounted() {
        this.fetchTeammates();

        /*
               await axios
                   .request(config)
                   .then((response) => {
                       this.message = response.data.message;
                   })
                   .catch((error) => {
                      
                       console.log(error);
                   });*/
    },

    computed: {
        filteredProjects() {
            return this.projects1.filter((project) => {
                const searchField = project.first_name ? project.first_name : project.name;
                return searchField.toLowerCase().includes(this.searchQuery.toLowerCase());
            });
        },
    },

    methods: {
        async confirmDelete() {
            // Add your deletion logic here
            await this.deleteTeamate(this.currentID);
            this.showModal = false; // Close modal after confirmation
        },

        async confirmEdit() {
            // Add your deletion logic here
            this.showModal1 = false; // Close modal after confirmation
            this.fetchTeammates();

            this.$router.go();
            setTimeout(() => {
                this.isVisible = false;
            }, 5000);
            if (this.selectedRole == "Colaborator" || this.selectedRole == "Admin") {
                let data = JSON.stringify({
                    idU: this.currentID,
                    CompanyID: this.Store.company_id,
                    Type: this.selectedRole,
                });
                let config = {
                    method: "post",
                    maxBodyLength: Infinity,
                    url: `https://server.go-platform.com/company/UpdateTeamates`,
                    headers: {
                        "Content-Type": "application/json",
                    },
                    data: data,
                    withCredentials: true,
                };

                await axios
                    .request(config)
                    .then((res) => {
                        console.log(res);
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            }
        },
        cancelDelete() {
            this.showModal = false; // Close modal without action
            this.showModal1 = false; // Close modal after confirmation
        },

        async deleteTeamate(userID) {
            let data = JSON.stringify({
                idU: userID,
                CompanyName: this.Store.company_name,
            });

            let config = {
                method: "post",
                maxBodyLength: Infinity,
                url: `https://server.go-platform.com/company/DeleteTeamates`,
                headers: {
                    "Content-Type": "application/json",
                },
                data: data,
                withCredentials: true,
            };

            await axios
                .request(config)
                .then((res) => {
                    console.log(res);
                })
                .catch((err) => {
                    console.log(err);
                });
            this.showModal = false; // Close modal after confirmation
            this.fetchTeammates();

            this.$router.go();
            setTimeout(() => {
                this.isVisible = false;
            }, 5000);

            //}
        },

        async fetchTeammates() {
            try {
                let data = JSON.stringify({
                    company_name: this.Store?.company_name || "Test Company", // Fallback to a test value
                    company_id: this.Store?.company_id,
                });

                let config = {
                    method: "post",
                    maxBodyLength: Infinity,
                    url: "https://server.go-platform.com/company/fetchTeamates",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    data: data,
                    withCredentials: true,
                };

                const response = await axios.request(config);

                // Log response data if successful
                this.message = response.data.message;
                this.projects1 = response.data.usersWithRoles;
                //  for (var i = 0; i < this.projects1.length; i++) this.projects1[i].register = this.projects1[i].register.toISOString().split("T")[0];
                //console.log(this.projects1);
            } catch (error) {
                console.error("Request failed:", error);
                console.error("Error details:", error.response?.data);
            }
        },
        setActiveTab(tab) {
            this.activeTab = tab;
        },
    },
};
</script>

<style scoped>
/* Scoped styles are not needed as Tailwind handles most styling. */

.company-container {
    padding: 24px 8px;
}
.navigation-tabs {
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* margin-top: 48px; */
    padding-right: 32px;
    padding-left: 48px;
    border-bottom: 2px solid #edeff2;
    margin-bottom: 25px;
}
.nav-links > a {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
    padding-top: 0px;
    padding-bottom: 24px;
    text-decoration: none;
    font-family: "Roboto";
    font-weight: 700;
    font-size: 14px;
    color: #7d8fb3;
    margin-right: 24px;
    position: relative;
}

.nav-links .nav-icon {
    font-size: 20px;
}

.nav-links img {
    margin-right: 12px;
}
.nav-links a::after {
    content: "";
    background: #7d8fb3;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 0;
    height: 3px;
    transition: width 0.4s ease-in-out;
    border-radius: 25px;
}

.nav-links > a:hover::after {
    width: 100%;
}

.nav-links a.active::after {
    background: #2196f3;
    width: 100%;
}

.nav-links a.active * {
    color: #2196f3;
}
</style>
